import * as Types from '../../gql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetIsActivatedFeatureToggleBadTipperDisclaimerQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetIsActivatedFeatureToggleBadTipperDisclaimerQuery = { __typename?: 'Query', getIsActivatedFeatureToggleBadTipperDisclaimer: { __typename?: 'Booleanh9df5pJ6', object?: boolean | null, error?: { __typename?: 'Booleanh9df5pJ6ErrorObject', code?: string | null, message: string } | null } };

export type GetIsNewMobileFormCreationFlowQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetIsNewMobileFormCreationFlowQuery = { __typename?: 'Query', getIsNewMobileFormCreationFlow: { __typename?: 'BooleanwSWNPcPl', object?: boolean | null, error?: { __typename?: 'BooleanwSWNPcPlErrorObject', code?: string | null, message: string } | null } };

export type GetIsStripeIssuingAllowedQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetIsStripeIssuingAllowedQuery = { __typename?: 'Query', getIsStripeIssuingAllowed: { __typename?: 'BooleanResponseObject', object?: boolean | null } };


export const GetIsActivatedFeatureToggleBadTipperDisclaimerDocument = gql`
    query getIsActivatedFeatureToggleBadTipperDisclaimer {
  getIsActivatedFeatureToggleBadTipperDisclaimer {
    object
    error {
      code
      message
    }
  }
}
    `;

/**
 * __useGetIsActivatedFeatureToggleBadTipperDisclaimerQuery__
 *
 * To run a query within a React component, call `useGetIsActivatedFeatureToggleBadTipperDisclaimerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIsActivatedFeatureToggleBadTipperDisclaimerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsActivatedFeatureToggleBadTipperDisclaimerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIsActivatedFeatureToggleBadTipperDisclaimerQuery(baseOptions?: Apollo.QueryHookOptions<GetIsActivatedFeatureToggleBadTipperDisclaimerQuery, GetIsActivatedFeatureToggleBadTipperDisclaimerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIsActivatedFeatureToggleBadTipperDisclaimerQuery, GetIsActivatedFeatureToggleBadTipperDisclaimerQueryVariables>(GetIsActivatedFeatureToggleBadTipperDisclaimerDocument, options);
      }
export function useGetIsActivatedFeatureToggleBadTipperDisclaimerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIsActivatedFeatureToggleBadTipperDisclaimerQuery, GetIsActivatedFeatureToggleBadTipperDisclaimerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIsActivatedFeatureToggleBadTipperDisclaimerQuery, GetIsActivatedFeatureToggleBadTipperDisclaimerQueryVariables>(GetIsActivatedFeatureToggleBadTipperDisclaimerDocument, options);
        }
export type GetIsActivatedFeatureToggleBadTipperDisclaimerQueryHookResult = ReturnType<typeof useGetIsActivatedFeatureToggleBadTipperDisclaimerQuery>;
export type GetIsActivatedFeatureToggleBadTipperDisclaimerLazyQueryHookResult = ReturnType<typeof useGetIsActivatedFeatureToggleBadTipperDisclaimerLazyQuery>;
export type GetIsActivatedFeatureToggleBadTipperDisclaimerQueryResult = Apollo.QueryResult<GetIsActivatedFeatureToggleBadTipperDisclaimerQuery, GetIsActivatedFeatureToggleBadTipperDisclaimerQueryVariables>;
export const GetIsNewMobileFormCreationFlowDocument = gql`
    query getIsNewMobileFormCreationFlow {
  getIsNewMobileFormCreationFlow {
    object
    error {
      code
      message
    }
  }
}
    `;

/**
 * __useGetIsNewMobileFormCreationFlowQuery__
 *
 * To run a query within a React component, call `useGetIsNewMobileFormCreationFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIsNewMobileFormCreationFlowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsNewMobileFormCreationFlowQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIsNewMobileFormCreationFlowQuery(baseOptions?: Apollo.QueryHookOptions<GetIsNewMobileFormCreationFlowQuery, GetIsNewMobileFormCreationFlowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIsNewMobileFormCreationFlowQuery, GetIsNewMobileFormCreationFlowQueryVariables>(GetIsNewMobileFormCreationFlowDocument, options);
      }
export function useGetIsNewMobileFormCreationFlowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIsNewMobileFormCreationFlowQuery, GetIsNewMobileFormCreationFlowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIsNewMobileFormCreationFlowQuery, GetIsNewMobileFormCreationFlowQueryVariables>(GetIsNewMobileFormCreationFlowDocument, options);
        }
export type GetIsNewMobileFormCreationFlowQueryHookResult = ReturnType<typeof useGetIsNewMobileFormCreationFlowQuery>;
export type GetIsNewMobileFormCreationFlowLazyQueryHookResult = ReturnType<typeof useGetIsNewMobileFormCreationFlowLazyQuery>;
export type GetIsNewMobileFormCreationFlowQueryResult = Apollo.QueryResult<GetIsNewMobileFormCreationFlowQuery, GetIsNewMobileFormCreationFlowQueryVariables>;
export const GetIsStripeIssuingAllowedDocument = gql`
    query getIsStripeIssuingAllowed {
  getIsStripeIssuingAllowed {
    object
  }
}
    `;

/**
 * __useGetIsStripeIssuingAllowedQuery__
 *
 * To run a query within a React component, call `useGetIsStripeIssuingAllowedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIsStripeIssuingAllowedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsStripeIssuingAllowedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIsStripeIssuingAllowedQuery(baseOptions?: Apollo.QueryHookOptions<GetIsStripeIssuingAllowedQuery, GetIsStripeIssuingAllowedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIsStripeIssuingAllowedQuery, GetIsStripeIssuingAllowedQueryVariables>(GetIsStripeIssuingAllowedDocument, options);
      }
export function useGetIsStripeIssuingAllowedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIsStripeIssuingAllowedQuery, GetIsStripeIssuingAllowedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIsStripeIssuingAllowedQuery, GetIsStripeIssuingAllowedQueryVariables>(GetIsStripeIssuingAllowedDocument, options);
        }
export type GetIsStripeIssuingAllowedQueryHookResult = ReturnType<typeof useGetIsStripeIssuingAllowedQuery>;
export type GetIsStripeIssuingAllowedLazyQueryHookResult = ReturnType<typeof useGetIsStripeIssuingAllowedLazyQuery>;
export type GetIsStripeIssuingAllowedQueryResult = Apollo.QueryResult<GetIsStripeIssuingAllowedQuery, GetIsStripeIssuingAllowedQueryVariables>;